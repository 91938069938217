import React from "react"
import Image from "gatsby-image"
import { Box, Flex, Heading, PseudoBox, SimpleGrid } from "@chakra-ui/core"

import useInstagram from "../hooks/use-instagram"

const Instagram = () => {
  return null
  // data
  // const instagramPhotos = useInstagram()

  // return (
  //   <>
  //     <Box width="100%" textAlign="center" mt="12" mb="6">
  //       <Heading color="pink.500">Ajankohtaista</Heading>
  //     </Box>
  //     <SimpleGrid columns={{ sm: 2, md: 4 }} spacing="40px">
  //       {/* show posts */}
  //       {instagramPhotos.map(photo => (
  //         <PseudoBox
  //           as="a"
  //           key={photo.id}
  //           href={`https://instagram.com/p/${photo.id}/`}
  //           boxShadow="md"
  //           rounded="md"
  //           _hover={{ border: "4px", color: "pink.500", boxShadow: "" }}
  //         >
  //           <Image fluid={photo.fluid} alt={photo.caption}></Image>
  //         </PseudoBox>
  //       ))}
  //     </SimpleGrid>
  //   </>
  // )
}

export default Instagram
