import React from "react"
import { Link } from "gatsby"
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  theme,
  ThemeProvider,
  CSSReset,
} from "@chakra-ui/core"

import Layout from "../components/layout"
import Instagram from "../components/instagram"
import Hero from "../components/hero"
import Video from "../components/video"

const breakpoints = ["360px", "768px", "1024px", "1440px"]
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

const newTheme = {
  ...theme,
  breakpoints,
}

function App() {
  return (
    <ThemeProvider theme={newTheme}>
      <CSSReset />
      <Layout>
        <Hero />
        <Box maxW={theme.breakpoints.lg} mx="auto">
          {/* <p>
            Päivitämme parhaillamme sivujamme. Vieraile sillä välin{" "}
            <a href="https://fi-fi.facebook.com/Onnistua.fi/">
              Facebook-sivuillamme
            </a>
            , soita numeroon <a href="tel:+358413181305">041 3181305</a> tai
            laita sähköpostia osoitteeseen{" "}
            <a href="mailto:varaukset@onnistua.fi">varaukset@onnistua.fi</a>.
          </p>
          <p>
            <Link to="/ajanvaraus">Ajanvaraus</Link> toimii kuitenkin
            normaalisti.
          </p>

          <p>
            <em>Terkuin Vilma</em>
          </p> */}
          <Instagram />
        </Box>
        <Box w="100%" bg="pink.50" mt="10" textAlign="center" p="6">
          <Box maxW={theme.breakpoints.lg} mx="auto">
            <Video
              videoSrcURL="https://www.youtube.com/embed/se2rBBqqg_Q"
              videoTitle="Onnistua.fi:n esittelyvideo"
            />
          </Box>
        </Box>
      </Layout>
    </ThemeProvider>
  )
}

export default App
