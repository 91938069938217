import React from "react"
import { css } from "@emotion/core"

// CSS styles keeps the 16:9 aspect ratio of the video and takes full width
// of the container

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div
    css={css`
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
    `}
  >
    <iframe
      css={css`
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      `}
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
)

export default Video
